import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Dharmu Mishra",
      position: "Coworker at Kinesso",
      src: "images/testimonial/avatar.png",
      desc: "“It's been a wonderful journey working with Timóteo. He possesses exceptional proficiency in technology. He consistently demonstrates solid work with perfect dedication towards success. Timóteo is very structured and logical in his approach to handling and solving situations and problems, emphasizing assumptions and risks at the right time. He is self-motivated, dedicated, methodical, and very capable of handling his responsibilities. He is a true asset to the company.”",
    },
    {
      name: "Andre Stamatto",
      position: "Coworker at Seplag",
      src: "images/testimonial/avatar.png",
      desc: "“Timoteo is a great system developer and professional, he's dedicated, focused and he knows work with any team. He has a great knowledge in many programming languages and frameworks: Java web (JSF, Hibernate, JPA, EJB, CDI, etc...), Angular JS 2.0, Android, php, Javascript and more.”",
    },
    {
      name: "Daniel Jorge",
      position: "Coworker at Seplag",
      src: "images/testimonial/avatar.png",
      desc: "“Timóteo has a great knowledge and skills in software development and he is a good person to work with. Focused and professional.”",
    },
    {
      name: "Rafaelle Weran",
      position: "Coworker at Seplag",
      src: "images/testimonial/avatar.png",
      desc: "“Timoteo is a highly competent, focused, and dedicated professional in what he does. He works well in a team and shares knowledge, thus adding value to the team.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Coworkers Speak</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          What people that worked with me say
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
