import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Programming",
      desc: "I work with front-end, backend and mobile development, using languages and frameworks such as Angular, Reactjs, React Native, Java, Springboot, Node and Nestjs.",
      icon: "fas fa-code",
    },
    {
      name: "DevOps",
      desc: "I have experience deploying apps in production, build configurations, continuous integration and continuous delivery.",
      icon: "fas fa-rocket",
    },
    {
      name: "Distributed teams",
      desc: "I work using Portuguese, English and Spanish dealing with distributed teams in the US, Mexico, Argentina, Peru, Europe and Índia.",
      icon: "fas fa-globe",
    },
    {
      name: "Projects",
      desc: "I have participated in many kinds of projects such as a Doctor's Appointment System, Official Medical Examination System of Ceara, a System for Supplying Stations for Electrical Cars, a Car Tracking System, a Stockroom and Movable Property Management System, a Clocking-on Control System, ETL platform, condominium management, invoice emission etc.",
      icon: "fas fa-cogs",
    },
    {
      name: "Responsive Design",
      desc: "While developing apps I always have the concern of making them able to adapt to different devices sizes that access it.",
      icon: "fas fa-mobile",
    },
    {
      name: "Industries",
      desc: "I have worked in a software factory, in a Science Institute, for the government of State of Ceara, Dell Computers, and currently I am working for international companies based on the US besides working in some personal projects.",
      icon: "fas fa-industry",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
