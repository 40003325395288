import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DETAILED: "Details",
    MOCKUPS: "Mockups",
    YOUTUBE: "Youtube Videos",
    VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Storybook Component Library",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I have worked with a new design system for the web app and I have developed a React component library for it using also storybook to showcase the components. Since we had several squads working on the same web app started to use micro frontends, each one using the library.",
        client: "Luxury Presence",
        technologies: "React, Storybook, Figma",
        industry: "Frontend Development",
        date: "2021",
        url: {
          name: "https://www.luxurypresence.com",
          link: "https://www.luxurypresence.com/",
        },

        sliderItems: [
          { type: 'video', videoId: 'kMEhUzvg7NI' },
          { type: 'image', src: "images/projects/uxcore.png" },
        ],
      },

      thumbImage: "images/projects/storybook2.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Real Estate Web App",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "My main assignment is to create new features for the Real State app of LuxuryPresence in the USA. Since the company has several different teams working on the same app I am also working on redesigning and improving the current architecture by providing solutions to give the teams more independency and still follow the best practices.",
        client: "Luxury Presence",
        technologies: "React, Node, Nestjs, Typescript, AWS",
        industry: "FullStack Development",
        date: "2021",
        url: {
          name: "https://www.luxurypresence.com",
          link: "https://www.luxurypresence.com/",
        },

        sliderItems: [
          { type: 'video', videoId: 'SyF-3qhx3uY' },
        ],
      },

      thumbImage: "images/projects/realestate.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Real Estate Mobile App",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Working on the new company's mobile app using react native where real estate agents are able to search for properties, favorite them, invite clients, save notes, etc",
        client: "Luxury Presence",
        technologies: "React Native, Typescript",
        industry: "Frontend Development",
        date: "2021",
        url: {
          name: "https://www.luxurypresence.com",
          link: "https://www.luxurypresence.com/",
        },

        sliderItems: [
          { type: 'video', videoId: 'lyww1ECTve8' },
        ],
      },

      thumbImage: "images/projects/mobileapp.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Invoice Emission Web App",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I have developed a web and mobile app for invoice emission in Ecuador which is already in use by several companies. The project was built with angular 10, Typescript and springboot .",
        client: "Veronica",
        technologies: "React Native, Typescript",
        industry: "FullStack Development",
        date: "2021",
        url: {
          name: "https://veronica.ec",
          link: "https://veronica.ec/",
        },

        sliderItems: [
          { type: 'video', videoId: 'MkrOhT2Zv8Y' },
        ],
      },

      thumbImage: "images/projects/veronica.png",

      categories: [filters.DETAILED],
    },
    // {
    //   title: "Mockups Design 1",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-2.jpg",

    //   categories: [filters.MOCKUPS],
    // },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Detailed Project 2",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },
    //     sliderImages: [
    //       "images/projects/project-1.jpg",
    //       "images/projects/project-2.jpg",
    //     ],
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          {/* <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul> */}
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            {/* <span className="text-light">Category</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
