import React from "react";
const AboutUs = () => {

  function differenceInYears(targetDate) {
    // Get the current date
    const currentDate = new Date();
  
    // Calculate the difference in milliseconds
    const timeDifference = currentDate - targetDate;
  
    // Convert milliseconds to years (approximate, assuming a year has 365.25 days)
    const yearsDifference = timeDifference / (365.25 * 24 * 60 * 60 * 1000);
  
    return Math.floor(yearsDifference);
  }
  const yearsOfExperience = differenceInYears(new Date('2010-01-01'));
  const age = differenceInYears(new Date('1986-12-18'));

  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Know Me More
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hi, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Timoteo Jorge
              </span>
            </h2>
            <p className="text-5">
            Greetings! I'm Timoteo Jorge, a {age}-year-old Computer Science graduate from the Federal University of Ceará. Based in Fortaleza, I'm a passionate technology enthusiast with a wealth of experience. I've contributed to diverse projects, including systems for medical facilities, electric car stations, and inventory management. My journey spans roles at Dell Computers, a Science Institute, and now, collaborations with US-based international companies. I thrive on innovation and bring a dynamic skill set to every endeavor.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  {yearsOfExperience}
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experience</span>
              </h3>
            </div>
          </div>
        </div>
        {/* <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Callum Smith</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:chat@callum.com">
                chat@callum.com
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-dark fw-600 mb-0">1 November, 1987</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Los Angeles, USA.</p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutUs;
