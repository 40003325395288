import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2004 - 2010",
      title: "Bachelor in Computer Science",
      place: "Federal University of Ceara",
      desc: "I got a solid foundation in both theoretical and practical aspects of the field. This education has been instrumental in shaping my skills for a successful career in technology.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2021 - Present",
      title: "Senior Fullstack Engineer",
      place: "LuxuryPresence",
      desc: "Designing features, enhancing architecture, and fostering independence in teams for a Real Estate app using React, Node.js, GraphQL, and micro frontends."
    },
    {
      yearRange: "2020 - 2021",
      title: "Senior Fullstack Engineer",
      place: "Maslo",
      desc: "Customized AI helper app for Health, Sports, and Coaching industries, integrating GPT-3, using React, React Native, Node.js, and Firebase."
    },
    {
      yearRange: "2019 - 2021",
      title: "Senior Fullstack Engineer",
      place: "Kinesso",
      desc: "Maintained and developed features for an advertising data processing app using Spring Boot, Angular 7, AWS services, and websockets."
    },
    {
      yearRange: "2018 - 2019",
      title: "Senior Fullstack Engineer",
      place: "Dell",
      desc: "Managed Dell's global parts web app, utilizing Spring Boot, React, and microservices with Node.js. Implemented JMS for global replenishment orders."
    },
  ];

  const skills = [
    {
      name: "React",
      percent: 95,
    },
    {
      name: "Node",
      percent: 95,
    },
    {
      name: "Angular",
      percent: 85,
    },
    {
      name: "Java",
      percent: 80,
    },
    {
      name: "Nestjs",
      percent: 90,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
